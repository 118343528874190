import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-100" }
const _hoisted_2 = { class: "pb-5" }
const _hoisted_3 = { class: "d-flex align-items-center landingSubtitle" }
const _hoisted_4 = { class: "pb-2" }
const _hoisted_5 = { class: "fw-bolder d-flex align-items-center text-dark groupLabel" }
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenoresSelector = _resolveComponent("MenoresSelector")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t("paso1de2")), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.$t("DatosMenoresGroupLabel")), 1)
    ]),
    _createElementVNode("div", {
      class: "fs-6 pb-2 textoJustificado",
      innerHTML: _ctx.$t('DatosFamiliaMenoresText')
    }, null, 8, _hoisted_6),
    _createVNode(_component_MenoresSelector, {
      list: _ctx.familiaMenores,
      selected: _ctx.selected,
      "onUpdate:selected": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event))
    }, null, 8, ["list", "selected"]),
    _createVNode(_component_ErrorMessage, {
      name: "menores",
      class: "fv-plugins-message-container invalid-feedback"
    })
  ]))
}