
import CondicionesPrivacidadModal from "@/components/modals/CondicionesPrivacidad.vue";
import DeclaracionResponsableModal from "@/components/modals/DeclaracionResponsable.vue";
import FamiliasModule from "@/store/modules/FamiliasModule";
import ServiciosProgramadosModule from "@/store/modules/ServiciosProgramadosModule";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { ErrorMessage, useField } from "vee-validate";
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";

export default defineComponent({
  name: "step-1",
  components: {
    ErrorMessage,
    CondicionesPrivacidadModal,
    DeclaracionResponsableModal,
  },
  setup() {
    const { value: politicaPrivacidad } = useField("politicaPrivacidad");
    const { value: declaracionResponsable } = useField<boolean>(
      "declaracionResponsable"
    );

    const store = useStore();
    const module = getModule(ServiciosProgramadosModule, store);
    const familiaModule = getModule(FamiliasModule, store);

    const dia =
      format(new Date(), "eeee", { locale: es }) +
      ", " +
      format(new Date(), "dd-MM-yyyy");

    return {
      politicaPrivacidad,
      declaracionResponsable,
      dni: computed(() => module.contacto?.dni ?? familiaModule.familia?.dni),
      telefono: computed(
        () => module.contacto?.telefono ?? familiaModule.familia?.telefono
      ),
      dia,
    };
  },
});
