
import { ServicioMenorSimple } from "@/types";
import { format } from "date-fns";
import {
  WritableComputedRef,
  computed,
  defineComponent,
  onMounted,
  ref,
} from "vue";

export default defineComponent({
  name: "iam-menores-list",
  components: {},
  props: {
    list: Object as () => ServicioMenorSimple[],
    onItemTap: Function,
    selected: {
      type: Object as () => Record<string, boolean>,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const selectedInput = ref(props.selected);
    onMounted(() => {
      selectedInput.value = props.selected;
    });
    const selectedValue: WritableComputedRef<Record<string, boolean>> =
      computed({
        get(): Record<string, boolean> {
          return props.selected;
        },
        set(value: Record<string, boolean>): void {
          selectedInput.value = value;
          emit("update:itemsPerPage", value);
        },
      });

    return {
      format,
      selectedValue,
    };
  },
});
