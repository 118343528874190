
import MenoresSelector from "@/components/widgets/lists/MenoresSelector.vue";
import { handleError } from "@/core/helpers/errorHandler";
import FamiliasModule from "@/store/modules/FamiliasModule";
import { ServicioMenorSimple } from "@/types";
import { ErrorMessage, useField } from "vee-validate";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";

export default defineComponent({
  name: "step-3",
  components: {
    MenoresSelector,
    ErrorMessage,
  },
  data() {
    return {
      menorData: {},
      isCreate: false,
      isSimple: this.token ? true : false,
    };
  },
  setup() {
    const { value: list, errorMessage } =
      useField<ServicioMenorSimple[]>("menores");
    const route = useRoute();
    const token = route.query.act;

    const store = useStore();
    const moduleFamilias = getModule(FamiliasModule, store);

    const isLoading = ref(false);
    const error = ref();
    error.value = null;
    const fetch = async () => {
      try {
        isLoading.value = true;
        error.value = null;
        await moduleFamilias.fetchMenores();
      } catch (err) {
        error.value = err;
        handleError(err);
      } finally {
        isLoading.value = false;
      }
    };

    const menores = computed(() =>
      moduleFamilias.menores.map((ele) => {
        return {
          ...ele,
          menorId: ele.id,
        };
      })
    );
    const selected = ref<Record<string, boolean>>({});
    const menoresSeleccionados = computed(() => {
      return familiaMenores.value.filter(
        (ele) => selected.value[ele.menorId as string]
      );
    });
    const familiaMenores = computed(() => {
      return menores.value.map((ele) => {
        return {
          id: new Date().getTime(),
          tratamiento: ele.tratamiento,
          nombre: ele.nombre,
          apellidos: ele.apellidos,
          fechaNacimiento: new Date(ele.fechaNacimiento),
          menorId: ele.menorId,
        };
      });
    });

    onMounted(() => fetch());

    watch(
      () => menoresSeleccionados.value,
      (val) => {
        list.value = val;
      }
    );

    return {
      familiaMenores,
      errorMessage,
      token,
      selected,
      menoresSeleccionados,
    };
  },
});
